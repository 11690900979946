const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/core/layout'),
    children: [
      { 
        path: '/home',
        name: 'home',
        component: () => import('@/views/core/home'),
        meta: { title: '老板服务', auth: false }
      },
      { 
        path: '/user',
        name: 'user',
        component: () => import('@/views/core/user'),
        meta: { title: '个人中心', auth: false }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: { title: '登录', auth: false }
  },
  {
    path: '/mypro',
    name: 'mypro',
    component: () => import('@/views/user/mypro'),
    meta: { title: '已购产品', auth: true }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/user/address'),
    meta: { title: '管理服务地址', auth: true }
  },
  {
    path: '/address/add',
    name: 'addressAdd',
    component: () => import('@/views/user/address/add'),
    meta: { title: '管理服务地址', auth: true }
  },
  {
    path: '/address/edit/:id',
    name: 'addressEdit',
    component: () => import('@/views/user/address/edit'),
    meta: { title: '管理服务地址', auth: true }
  },
  {
    path: '/charging',
    name: 'charging',
    component: () => import('@/views/user/charging'),
    meta: { title: '收费标准', auth: true }
  },
  {
    path: '/guarantee',
    name: 'guarantee',
    component: () => import('@/views/user/guarantee'),
    meta: { title: '服务保修', auth: true }
  },
  {
    path: '/order',
    redirect: '/order/submit',
    component: () => import('@/views/order/list/layout'),
    children: [
      {
        path: '/order/submit',
        name: 'orderSubmit',
        component: () => import('@/views/order/list/submit'),
        meta: { title: '我的工单', auth: true }
      },
      {
        path: '/order/dispatch',
        name: 'orderDispatch',
        component: () => import('@/views/order/list/dispatch'),
        meta: { title: '我的工单', auth: true }
      },
      {
        path: '/order/inservice',
        name: 'orderInservice',
        component: () => import('@/views/order/list/inservice'),
        meta: { title: '我的工单', auth: true }
      },
      {
        path: '/order/complete',
        name: 'orderComplete',
        component: () => import('@/views/order/list/complete'),
        meta: { title: '我的工单', auth: true }
      },
      {
        path: '/order/cancel',
        name: 'orderCancel',
        component: () => import('@/views/order/list/cancel'),
        meta: { title: '我的工单', auth: true }
      },
      {
        path: '/logistics',
        name: 'logistics',
        component: () => import('@/views/order/list/logistics'),
        meta: { title: '我的工单', auth: true }
      }
    ],
  },
  {
    path: '/order/result',
    component: () => import('@/views/order/result'),
    meta: { title: '老板服务', auth: true }
  },
  {
    path: '/maintenance/list',
    component: () => import('@/views/maintenance/list'),
    meta: { title: '故障排查', auth: false }
  },
  {
    path: '/maintenance/detail',
    component: () => import('@/views/maintenance/detail'),
    meta: { title: '故障排查', auth: false }
  },
  {
    path: '/service',
    redirect: '/service/cg',
    component: () => import('@/views/service/layout'),
    children: [
      {
        // 换新
        path: '/service/hx',
        name: 'serviceHX',
        component: () => import('@/views/service/category/hx'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 厨改
        path: '/service/cg',
        name: 'serviceCG',
        component: () => import('@/views/service/category/cg'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 送货
        path: '/service/sh',
        name: 'serviceSH',
        component: () => import('@/views/service/category/sh'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // KDS
        path: '/service/kds',
        name: 'serviceKDS',
        component: () => import('@/views/service/category/kds'),
        meta: { title: '老板服务', auth: true }
      },
      
      {
        // 前置
        path: '/service/qz',
        name: 'serviceQZ',
        component: () => import('@/views/service/category/qz'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 安装
        path: '/service/az',
        name: 'serviceAZ',
        component: () => import('@/views/service/category/az'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 维修
        path: '/service/wx',
        name: 'serviceWX',
        component: () => import('@/views/service/category/wx'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 保养
        path: '/service/by',
        name: 'serviceBY',
        component: () => import('@/views/service/category/by'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 清洗
        path: '/service/qx',
        name: 'serviceQX',
        component: () => import('@/views/service/category/qx'),
        meta: { title: '老板服务', auth: true }
      },
      {
        // 改气源
        path: '/service/gqy',
        name: 'serviceGQY',
        component: () => import('@/views/service/category/gqy'),
        meta: { title: '老板服务', auth: true }
      },
    ],
  },
  {
    path: '/logistics/detail/:id',
    name: 'logisticsDetail',
    component: () => import('@/views/order/detail/logistics'),
    meta: { title: '物流详情', auth: true }
  },
  {
    path: '/order/detail/:id',
    name: 'orderDetail',
    component: () => import('@/views/order/detail/detail'),
    meta: { title: '我的工单', auth: true }
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('@/views/user/userinfo'),
    meta: { title: '老板服务', auth: true }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/views/user/feedback'),
    meta: { title: '意见反馈', auth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/user/account'),
    meta: { title: '老板服务', auth: true }
  },
]

export default routes