import request from '@/utils/request'

// 获取账户信息
export function getUserInfo() {
  return request({
    url: '/member/get_member_info',
    method: 'post'
  })
}

// 获取账户信息
export function updateUserInfo(data) {
  return request({
    url: '/member/update_member_info',
    method: 'post',
    data
  })
}

// 获取分类列表
export function getPhoneCaptcha(data) {
  return request({
    url: '/common/msg',
    method: 'post',
    data
  })
}

// 登录
export function onLogin(data) {
  return request({
    url: '/common/verify_code',
    method: 'post',
    data
  })
}

// 获取微信用户信息
export function getWxUser(data) {
  return request({
    url: '/common/get_user',
    method: 'post',
    data
  })
}

// 获取微信用户信息
export function bindPhoneNumber(data) {
  return request({
    url: '/member/bind_mobile',
    method: 'post',
    data
  })
}

// 获取用户积分
export function getUserScore(data) {
  return request({
    url: '/member/sync_member_info',
    method: 'post',
    data
  })
}