import axios from 'axios'
import Cookies from 'js-cookie';
import { Toast } from 'vant'
// import { getID, getToken } from '@/utils/auth'
// import sha1 from 'js-sha1'
// import store from '@/store'
// import router from '@/router'
import md5 from 'md5'


// create an axios instance
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.headers.post['Content-Type'] = 'application/json';
const service = axios.create({
  // baseURL: process.env.NODE_ENV == 'development' ? '/index.php/api' : process.env.VUE_APP_BASE_URL + '/index.php/api', 
  baseURL: '/index.php/api_v2', 
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // const timestamp = Math.floor(Date.now()/1000)
    // const mid = getID()
    // const token = getToken()
    // config.data = {
    //   timestamp,
    //   token,
    //   sign: mid ? sha1(`robam${mid}${timestamp}${getToken()}`) : '',
    //   mid,
    //   ...config.data
    // }
    const timestamp = Date.now()
    config.data = {
      unionid: Cookies.get('user_unionid_v3') || '',
      // mobile: Cookies.get('user_mobile') || '',
      timestamp: timestamp,
      sign: md5(timestamp + 'ewqijfzdsq'),
      ...config.data,
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 10000, it is judged as an error.
    if (res.code !== 200) {
      Toast(res.msg || 'Error')
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.error('err' + error) // for debug
    Toast(error.msg || 'Error')
    return Promise.reject(error)
  }
)

export default service
