import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import '@/assets/iconfont/iconfont.css'
import 'vant/lib/index.less'
import '@/assets/style/index.less'
import '@/permission' 
import Amap from '@/utils/amap.js'

Vue.use(Amap)
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
