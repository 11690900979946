import router from './router'
import Cookies from 'js-cookie'
// import { Toast } from 'vant';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
// progress bar style
import getPageTitle from '@/utils/get-page-title'

const whiteList = ['/login', '/home', '/maintenance/detail', '/maintenance/list'] // no redirect whitelist

NProgress.configure({ showSpinner: false }) 

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  if (Cookies.get('hasLogin_v2') == 'true') {
    if (to.path === '/login') {
      next({ path: '/home' })
    } else {
      next()
    }
    NProgress.done()
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) >= 0) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
    NProgress.done()
  }
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})
