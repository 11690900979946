import AMapLoader from '@amap/amap-jsapi-loader';

const install = (Vue) => {
	AMapLoader.load({
		key: 'ad9626f3740dbda3203819c0acb7f1bb', // key
		plugins: ['AMap.Geolocation', 'AMap.Geocoder'], // 插件
		version: '2.0',
		Loca:{                // 是否加载 Loca， 缺省不加载
			"version": '2.0.0'  // Loca 版本，缺省 1.3.2
		},
	}).then((amap)=>{
		Vue.prototype.$Amap = amap
		window.Amap = amap
	}).catch(err=>{
		throw(new Error('加载高德地图失败：'+err,))
	})
}

export default {
	install
}
