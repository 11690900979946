<template>
  <div id="app">
    <!-- <div class="nav-bar" v-if="!isWxBrowser">
      <van-nav-bar
        :title="pageTitle"
        :left-arrow="hasleft"
        placeholder
        fixed
        @click-left="onClickLeft"
        z-index="70"
      />
    </div> -->
    <!-- <div class="page-scroll"> -->
      <router-view/>
    <!-- </div> -->
  </div>
</template>

<script>
  import wx from 'weixin-js-sdk'
  import Store from 'store'
  import Cookies from 'js-cookie'
  import { isWXBrowser, getUrlParams } from '@/utils/common'
  import { getWxUser } from '@/api/user'
  import { getWxConfig } from '@/api/common'
  import { NavBar } from 'vant'
  import getPageTitle from '@/utils/get-page-title'

  export default {
    name: 'App',
    components: {
      [NavBar.name]: NavBar
    },
    watch: {
      $route(val) {
        this.pageTitle = getPageTitle(val.meta.title)
        if (val.path == '/home' || val.path == '/user') {
          this.hasleft = false
        } else {
          this.hasleft = true
        }
      }
    },
    data() {
      return {
        pageTitle: '',
        hasleft: true,
        isWxBrowser: true
      }
    },
    created() {
      
      this.isWxBrowser = isWXBrowser()
      this.pageTitle = getPageTitle(this.$route.meta.title)
      this.androidInputBugFix();
      if (!this.isWXBrowser && window.location.href.indexOf('/maintenance') >= 0) {
        return
      }
      // 本地调试数据
      // Cookies.set('hasLogin_v2', true)
      // Cookies.set('user_unionid_v3', 'omJJE09Q7--CGcL1CdVzUSi-64Og')
      // Cookies.set('user_mobile', '')
      // Store.set('wx_user_v3', {"id":23111,"username":"对方正在输入...","headimg":"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erY0I9TW09gFIKF2Sw35tBGyjszzsTIvKQcfoOIvtiayFYI8y3j4rNVtANwllugGtGWQ9QdgZsBA6A/132","mobile":"","score":500,"level":"小板栗","unionid":"omJJE09Q7--CGcL1CdVzUSi-64Og"})
      if(!Cookies.get('user_unionid_v3') || !Cookies.get('hasLogin_v2') || Cookies.get('hasLogin_v2') == 'false' || !Store.get('wx_user_v3')){
        const urlSearch = window.location.search
        if (urlSearch.indexOf('code') < 0) {
          window.location.href ='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc1c4c92cdbb52812&redirect_uri='+encodeURIComponent(location.href)+'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
        } else {
          const code = getUrlParams('code');
          getWxUser({code: code}).then(res => {
            const { userinfo } = res.data
            Store.set('wx_user_v3', userinfo)
            Cookies.set('user_unionid_v3', userinfo.unionid)
            /**
             * 没有手机号则表示没有绑定手机号 => 未登录
             */
            if (userinfo.mobile) {
              Cookies.set('hasLogin_v2', 'true')
            } else {
              Cookies.set('hasLogin_v2', 'false')
            }
          }).catch(err => {
            console.log(err)
            // window.location.href = "https://wxyy.hzrobam.com/pro/#/home"
          })
        }
      } else if (Store.get('wx_user_v3') && Cookies.get('user_unionid_v3')) {
        const userinfo = Store.get('wx_user_v3')
        if (userinfo.mobile) {
          Cookies.set('hasLogin_v2', 'true')
        } else {
          Cookies.set('hasLogin_v2', 'false')
        }
      }
      this.initWXConfig()
    },
    mounted() {
      // AMapLoader.load({
      //   "key": "ad9626f3740dbda3203819c0acb7f1bb",              // 申请好的Web端开发者Key，首次调用 load 时必填
      //   "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      //   "plugins": ['AMap.Geolocation', 'AMap.Geocoder'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      // }).then((AMap)=>{
      //   window.AMap = AMap
      // })
    },
    methods: {
      androidInputBugFix(){
        if (/Android/gi.test(navigator.userAgent)) {
          window.addEventListener('resize', function () {
            if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
              window.setTimeout(function () {
                document.activeElement.scrollIntoViewIfNeeded();
              }, 0);
            }
          })
        }
      },
      initWXConfig() {
        getWxConfig({url: window.location.href}).then(res => {
          const info = res.data.info
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: info.appId, // 必填，公众号的唯一标识
            timestamp: info.timestamp, // 必填，生成签名的时间戳
            nonceStr: info.nonceStr, // 必填，生成签名的随机串
            signature: info.signature,// 必填，签名
            jsApiList: ['hideMenuItems', 'scanQRCode', 'closeWindow'], // 必填，需要使用的JS接口列表
            openTagList:['wx-open-launch-weapp'] 
          });
          wx.ready( ()=> {   //需在用户可能点击分享按钮前就先调用
            wx.hideMenuItems({
              menuList: ['menuItem:copyUrl', 'menuItem:editTag', 'menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq',
                'menuItem:share:weiboApp', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:QZone', 'menuItem:originPage', 'menuItem:openWithQQBrowser',
                'menuItem:openWithSafari', 'menuItem:share:email'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
            })
          });
        })
      },
      onClickLeft() {
        if (window.history.length <= 1) {
          this.$router.push({path:'/home'})
          return false
        } else {
          this.$router.go(-1)
        }
      }
      
    },
    
  }
</script>

<style lang="less">
  #app {
    .nav-bar {
      .van-icon-arrow-left {
        color: #333333;
      }
    }
    .page-scroll {
      height: 0;
      flex: 1;
      overflow-y: auto;
      position: relative;
    }
  }
</style>