
import request from '@/utils/request'
import axios from 'axios'


// 获取账户列表
export function getHomeInfo() {
  return request({
    url: '/common/get_index_info',
    method: 'post'
  })
}

// 上传接口
export function uploadFile(data) {
  return axios({
    url: '/index.php/api/common/upload',
    method: 'post',
    data
  })
}

// 获取地址列表
export function getUserAddressList(data) {
  return request({
    url: '/member/get_member_address_list',
    method: 'post',
    data
  })
}

// 获取地址详情
export function getAddressDetail(data) {
  return request({
    url: '/member/get_member_address_info',
    method: 'post',
    data
  })
}

// 新增地址
export function createAddress(data) {
  return request({
    url: '/member/add_member_address',
    method: 'post',
    data
  })
}

// 编辑地址
export function updateAddress(data) {
  return request({
    url: '/member/update_member_address',
    method: 'post',
    data
  })
}

// 删除地址
export function deleteAddress(data) {
  return request({
    url: '/member/del_member_address',
    method: 'post',
    data
  })
}

// 已购产品
export function getMyProList(data) {
  return request({
    url: '/member/get_my_buy_product',
    method: 'post',
    data
  })
}

// 获取产品分类
export function getCategoryList(data) {
  return request({
    url: '/common/get_category_list ',
    method: 'post',
    data
  })
}

// 获取配件列表
export function getPartsList(data) {
  return request({
    url: '/common/get_component_from_cate',
    method: 'post',
    data
  })
}

// 获取产品费用
export function getFeeInfo(data) {
  return request({
    url: '/common/get_fee_from_cate',
    method: 'post',
    data
  })
}

// 保修政策
export function getGuaranteeInfo(data) {
  return request({
    url: '/common/get_cate_bx_info',
    method: 'post',
    data
  })
}

// 保修政策
export function getWxConfig(data) {
  return request({
    url: '/open/get_ticket',
    method: 'post',
    data
  })
}

// 发送客服消息
export function sendServiceMsg(data) {
  return request({
    url: '/index/sendmsg',
    method: 'post',
    data
  })
}

// 发送客服消息
export function addFeedback(data) {
  return request({
    url: '/member/add_feedback',
    method: 'post',
    data
  })
}

// 扫码获得产品
export function useCodeGetPro(data) {
  return request({
    url: '/member/scan_get_product',
    method: 'post',
    data
  })
}